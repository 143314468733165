<template>
  <main-layout>
    <v-container grid-list-xs>
      <v-row>
        <v-col
          cols="12"
          lg="10"
        >
          <h2 class="headline mb-3">Het Globaal Medisch Dossier</h2>
          <base-divider
            color="accent"
            dense
          />
          <p>
            Het Globaal Medisch Dossier is een beveiligd elektronisch medisch dossier dat uw huisarts over u bijhoudt.
            Dankzij dit persoonlijke gezondheidsdossier is een nauwere samenwerking tussen alle artsen die u raadpleegt mogelijk en
            kan belangrijke info over uw gezondheid worden uitgewisseld.
          </p>
          <p>
            Op basis van het GMD heeft de arts een totaalbeeld van uw gezondheidstoestand wat noodzakelijk is voor een goede medische begeleiding.
            Het is dus belangrijk voor de volledigheid van uw medisch dossier om gegevens over allergie, medicatie, vaccinaties,
            een bezoek aan een specialist met of zonder doorverwijzing, ... steeds te laten opnemen in uw dossier.
          </p>
          <p>
            Bij verhuis of wijziging van huisarts kan het dossier, op uw vraag, doorgegeven worden aan de nieuwe huisarts.
            Pas het volgende kalenderjaar betaalt u dan de GMD-bijdrage aan deze arts.
          </p>
          <p>
            Indien u 75 jaar of ouder bent of indien u chronisch ziek bent (uw ziekenfonds bepaalt dit aan de hand van wettelijke criteria),
            geniet u ook van 30% vermindering op het remgeld tijdens een normaal huisbezoek.
            <br>
            <br>
            Voor meer informatie over het Globaal Medisch Dossier kan u terecht op de website van het riziv :	<a
            href="http://www.riziv.be"> www.riziv.be </a>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </main-layout>
</template>

<script>
  import MainLayout from '@/layouts/Main.vue'
  import BaseDivider from '@/components/Divider.vue'

  export default {
    head () {
      return { title: this.$t('gmdvac') }
    },
    components: {
      MainLayout,
      BaseDivider
    }
  }
</script>
